<template>
  <div class="co-modals">
    <!-- MFA success modal -->
    <b-modal
      modal-class="co-modals"
      centered
      size="md"
      :id="COMMAN_MODAL_IDS.MFA_SUCCESS_MODAL"
    >
      <template #modal-header="{ close }">
        <div class="w-100 text-right c-pointer">
          <img
            src="@/assets/images/close.svg"
            alt="close"
            @click="close()"
            width="14"
            height="14"
          />
        </div>
      </template>
      <div class="text-center ta-alert__content">
        <img
          src="@/assets/images/shared/security.svg"
          alt="credential illustration"
          width="auto"
          height="auto"
          class="mb-3"
        />
        <h5>{{ $t("mfa.success.heading") }}</h5>
        <p class="cd-sec__des">
          {{ $t("mfa.success.content", { s: allProfile.email }) }}
        </p>
      </div>

      <template slot="modal-footer">
        <div class="w-100 d-flex justify-content-center mb-3">
          <Button
            variant="outline-primary"
            pill
            className="mr-3"
            @click="$bvModal.hide(COMMAN_MODAL_IDS.MFA_SUCCESS_MODAL)"
          >
            {{ $t("general.dismiss") }}</Button
          >
        </div>
      </template>
    </b-modal>
    <!-- MFA configure modal -->
    <b-modal
      modal-class="co-modals"
      centered
      size="md"
      :id="COMMAN_MODAL_IDS.MFA_CONFIGURE_MODAL"
    >
      <template #modal-header="{ close }">
        <div class="w-100 text-right c-pointer">
          <img
            src="@/assets/images/close.svg"
            alt="close"
            @click="close()"
            width="14"
            height="14"
          />
        </div>
      </template>
      <div class="text-center ta-alert__content">
        <img
          src="@/assets/images/vle/timer/assignment-turned-in.svg"
          alt="credential illustration"
          width="50"
          height="50"
          class="mb-3"
        />
        <h5>{{ $t("mfa.configuration.heading") }}</h5>
        <p class="cd-sec__des">
          {{ $t("mfa.configuration.content") }}
        </p>
      </div>

      <template slot="modal-footer">
        <div class="w-100 d-flex justify-content-center mb-3">
          <Button
            variant="outline-primary"
            pill
            className="mr-3"
            @click="$bvModal.hide(COMMAN_MODAL_IDS.MFA_CONFIGURE_MODAL)"
          >
            {{ $t("general.dismiss") }}</Button
          >
          <Button
            variant="primary"
            pill
            className="mr-3"
            @click="
              $bvModal.hide(COMMAN_MODAL_IDS.MFA_CONFIGURE_MODAL);
              $emit('onSetup');
            "
          >
            {{ $t("general.setup") }}</Button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Button from "@/components/WrapperComponents/Button.vue";
import { mapGetters } from "vuex";

import COMMAN_MODAL_IDS from "./CommonModalConstants";
export default {
  components: { Button },
  computed: {
    ...mapGetters(["allProfile"])
  },
  data() {
    return {
      COMMAN_MODAL_IDS: {}
    };
  },
  mounted() {
    this.COMMAN_MODAL_IDS = COMMAN_MODAL_IDS;
  }
};
</script>
<style lang="scss">
.co-modals {
  .modal-dialog {
    .modal-body {
      h5 {
        color: #191c1d;
        letter-spacing: 0.2px;
      }
      p {
        color: #44464e;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
      }
    }
    .modal-footer {
      .btn {
        height: 45px;
        &.btn-outline-primary {
          color: $brand-primary-darker;
          border-color: $brand-primary-darker;
          background: #fff;
        }
        &.btn-primary {
          background: $brand-primary-darker;
          border-color: $brand-primary-darker;
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .co-modals {
    .modal-body {
      padding: 0 40px;
    }
    .modal-dialog {
      max-width: 580px;
    }
  }
}
</style>
